import React from "react";
import SubSystemHeader from "./SubSystemHeader";
import Topbar from "./Topbar";

const Header = () => {
    return (
        <React.Fragment>
            <div className="header-wrapper stuck">
                <Topbar />
                <SubSystemHeader />
            </div>
        </React.Fragment>
    );
};

export default Header;

export interface HeaderProps {
    showNavigation: boolean;
}
