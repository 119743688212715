import React, {useState} from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {ComponentChildrenProps} from "../../utils/componentUtils";

const FilterContainer = ({children, closed}: FilterContainerProps) => {
    //Filter section. Manage initial state of the filters by props
    const [collapseClass, setCollapseClass] = useState<string>(closed ? "closed" : "open");
    const [collapsed, setCollapsed] = useState<boolean>(closed);

    /** Toggles displaying of filters */
    const toggleFilters = (value: boolean) => {
        setCollapsed((value = !value));

        if (value) {
            setCollapseClass("closed");
            return;
        }
        setCollapseClass("open");
    };
    return (
        <React.Fragment>
            <div className={`filter-container-wrapper ${collapseClass}`}>
                <div
                    className={`row ml-0 mr-0 filter-container-control-row ${collapseClass}`}
                    onClick={() => toggleFilters(collapsed)}
                >
                    <div className="col d-flex align-items-center">
                        <h6 className={`mb-0 filter-label`}> Filters </h6>
                    </div>
                    <div className="col pr-0 d-flex justify-content-end align-items-center">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={collapsed ? "Expand" : "Collapse"}
                            onClick={() => toggleFilters(collapsed)}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                            className="mr-3"
                        />
                    </div>
                </div>
                <div className={`filter-container ${collapseClass} row ml-0 mr-0 mb-2`}>
                    {children}
                </div>
            </div>
        </React.Fragment>
    );
};

export default FilterContainer;

interface FilterContainerProps extends ComponentChildrenProps {
    closed: boolean;
}
