import React from "react";
import {ReportState} from "../../../../../api/im";
import {getUiFriendlyText} from "../../../../../utils/textUtils";
import {TbAlertTriangleFilled, TbCircleCheckFilled, TbSquareRoundedXFilled} from "react-icons/tb";

export default function ReportStateCell({state}: {state: ReportState}) {
    const name = getUiFriendlyText(state);
    const iconSize = "26px";
    let icon: React.ReactNode | undefined = undefined;

    switch (state) {
        case ReportState.PendingReview:
            icon = (
                <div className="text-danger">
                    <TbSquareRoundedXFilled size={iconSize} />
                </div>
            );
            break;
        case ReportState.UnderReview:
            icon = (
                <div className="text-warning">
                    <TbAlertTriangleFilled size={iconSize} />
                </div>
            );
            break;
        case ReportState.CompletedReview:
            icon = (
                <div className="text-success">
                    <TbCircleCheckFilled size={iconSize} />
                </div>
            );
            break;
    }

    return (
        <div className="mb-0 icon_block">
            {icon}
            <div>{name}</div>
        </div>
    );
}
