import React from "react";
import {AiOutlineExclamationCircle} from "react-icons/ai";

const ReportFormMessageBlock = (props: ReportFormMessageBlockProps) => (
    <div className="form_msg_block">
        <AiOutlineExclamationCircle size={30} />
        <p>{props.message}</p>
    </div>
);

export default ReportFormMessageBlock;

interface ReportFormMessageBlockProps {
    message: string;
}
