import React from "react";
import {ReportListing, ReportState} from "../../../../../api/im";
import {formatUnixToDDMMYYYY} from "../../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../../utils/textUtils";
import {useReportListMobileItem} from "./Hooks/useReportListMobileItem";
import MobileDeletionConfirmationModal from "./MobileDeletionConfirmation";
import ReportStateCell from "../Shared/ReportStateCell";

function ReportListMobileItem({report, onReportDeleted}: ReportListMobileItemProps) {
    const {getAuthorInitials, onClick, ref, onPointerUp, onPointerDown, loading, onDeleteReport} =
        useReportListMobileItem({report, onReportDeleted});

    return (
        <React.Fragment>
            <div className="mobile-list-item-wrapper" ref={ref}>
                <div
                    className="mobile-list-item"
                    onClick={onClick}
                    onPointerDown={onPointerDown}
                    onPointerUp={onPointerUp}
                >
                    <div className="mobile-list-item-left-wrapper">
                        <div className="mobile-view-initials-wrapper">
                            <div className="mobile-view-initials">{getAuthorInitials()}</div>
                        </div>
                    </div>

                    <div className="mobile-list-item-details">
                        <div className="mobile-list-item-detail-header">
                            {getUiFriendlyText(report.type)} -{" "}
                            {formatUnixToDDMMYYYY(report.dateCreated)}
                        </div>
                        <div className="mobile-list-item-detail-body">{report.calendarName}</div>
                        <div className="mobile-list-item-detail-additional-info header-font">
                            <ReportStateCell state={report.state} />
                        </div>
                    </div>
                </div>
                <MobileDeletionConfirmationModal
                    disabled={report.state !== ReportState.PendingReview}
                    loading={loading}
                    modalTitle={"Delete Report"}
                    modalBody={
                        "Are you sure you want to delete this report? This operation cannot be undone!"
                    }
                    onConfirm={onDeleteReport}
                />
            </div>
        </React.Fragment>
    );
}

export default ReportListMobileItem;

export interface ReportListMobileItemProps {
    report: ReportListing;
    onReportDeleted: () => Promise<void>;
}
