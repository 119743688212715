import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {Link} from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/gi";
import {NavBarContext} from "../Sidebar/navContext";

export default function TopTabs() {
    const navContext = useContext(NavBarContext);
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];

    return (
        <div className="page-container">
            <div className="top_tabs">
                {navItems.map((item) => {
                    if (item.path === undefined) return undefined;
                    return (
                        <Link to={item.path} key={item.path} className="top_tab">
                            {item.name}
                        </Link>
                    );
                })}
                <button
                    type="button"
                    className="mobile top_tab"
                    onClick={() => {
                        navContext?.setShow((prev) => !prev);
                    }}
                >
                    <GiHamburgerMenu />
                </button>
            </div>
        </div>
    );
}
