import React, {useContext} from "react";
import {TopBarItem} from "./Helpers/topbarHelpers";
import TopbarItem from "./TopbarItem";
import useMcConfig from "../../../../../Hooks/useMcConfig";
import LogoutButton from "../Buttons/LogoutButton";
import {Loading} from "store-fetch-wrappers";
import mcLogo from "../../../../../../assets/mc-logo.svg";
import {NavBarContext} from "../Sidebar/navContext";

const Topbar = () => {
    const {config, loading} = useMcConfig();
    const context = useContext(NavBarContext);

    return (
        <React.Fragment>
            <div id="nav-header" className="header nav-container h-100 flex-row">
                <div className="row ml-0 mr-0 w-100">
                    <div className="pl-0 col">
                        <img src={mcLogo} className="mc-logo" alt="" />
                    </div>
                    <div className="ml-auto pl-0 pr-0 d-flex justify-content-end">
                        <div className="desktop">
                            <ul className="link-container nav-links-right mb-0">
                                {loading ? (
                                    <Loading type={"dot"} showLoadingText={false} />
                                ) : (
                                    <React.Fragment>
                                        {config.navigation.items.map(
                                            (item: TopBarItem, index: number) => {
                                                return <TopbarItem {...item} key={index} />;
                                            }
                                        )}
                                        <LogoutButton />
                                    </React.Fragment>
                                )}
                            </ul>
                        </div>
                        <div className="mobile pr-2">HAMBURGER</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Topbar;
