import React, {useEffect, useRef, useState} from "react";
import {Report, ReportState} from "../../../../api/im";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import Dropdown from "../../../Dropdown/Dropdown";
import {useDispatch} from "react-redux";
import {nullifyReportStore, setReport} from "../../../../store/reports/actions/ReportActions";
import ReportTypeButtons from "./ReportTypeButtons";
import ReportFormContainer from "./ReportFormContainer";

const EditReportFormParent = (props: Report) => {
    const dispatch = useDispatch();
    const initialReport = useRef(props);
    const [completed, setCompleted] = useState<boolean>(false);

    useEffect(() => {
        if (initialReport.current.state === ReportState.CompletedReview) {
            setCompleted(true);
        }
        return () => {
            dispatch(nullifyReportStore());
        };
    }, []);

    return (
        <React.Fragment>
            <div>
                <FormHeader headerName={"Report Details"} />
                <FormRow rowName={"Reporter Name"} columnHeaderClassName={"pr-3"}>
                    <p className="mb-0">{props.staffName}</p>
                </FormRow>
                <FormRow rowName={"Date Created"} columnHeaderClassName={"pr-3"}>
                    <p className="mb-0">{formatUnixToDDMMYYYY(props.dateCreated)}</p>
                </FormRow>
                {props.id > 0 && (
                    <FormRow rowName={"Date Modified"} columnHeaderClassName={"pr-3"}>
                        <p className="mb-0">{formatUnixToDDMMYYYY(props.dateModified)}</p>
                    </FormRow>
                )}
                <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                    <FormRow rowName={"Report State"} columnHeaderClassName={"pr-3"}>
                        <p className="mb-0">{getUiFriendlyText(props.state)}</p>
                    </FormRow>
                </AuthAmI>
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <FormRow
                        rowName={"Report State"}
                        columnDetailClassName={completed ? "pl-3 pr-3" : "pl-0 pr-0"}
                    >
                        {completed ? (
                            <p className="mb-0">{getUiFriendlyText(props.state)}</p>
                        ) : (
                            <Dropdown
                                initialValue={props.state}
                                changeOption={(reportState) => {
                                    if (!reportState) return;
                                    dispatch(
                                        setReport({
                                            ...props,
                                            state: getReportStateFromString(reportState.toString())
                                        })
                                    );
                                }}
                                options={Object.keys(ReportState)}
                            />
                        )}
                    </FormRow>
                </AuthAmI>
                <ReportTypeButtons {...props} />
                <ReportFormContainer {...props} />
            </div>
        </React.Fragment>
    );
};

export default EditReportFormParent;

function getReportStateFromString(value: string): ReportState {
    return ReportState[value as keyof typeof ReportState];
}
