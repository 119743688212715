import React from "react";
import {ReporterDetailsV1} from "../../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../../Form/FormHeader";
import {PulseTable} from "pulse_table";

const ReporterDetails = (props: ReporterDetailsV1) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Reporter Details"} />
            <div className="space_table">
                <PulseTable
                    items={toTableRow(props)}
                    headers={{
                        name: "Name",
                        email: "Email",
                        skillLevel: "Skill Level",
                        telephone: "Telephone"
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">Could not load reporter details</h5>
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default ReporterDetails;

interface TableRow {
    name: string;
    email: string;
    skillLevel: string;
    telephone: string | undefined;
}

function toTableRow({name, email, skillLevel, telephone}: ReporterDetailsV1): TableRow[] {
    return [
        {
            name,
            email,
            skillLevel,
            telephone
        }
    ];
}
