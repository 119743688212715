import React from "react";
import {ReportListTableRow} from "./ReportListDesktopView";
import AuthAmI from "../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../api/staff";
import IconWithModal from "../../../../Icon/IconWithModal";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import Icon, {IconType} from "../../../../Icon/Icon";
import {deleteReportFromService} from "../../../../../store/reports/actions/ReportActions";
import Tooltip from "../../../../Tooltip/Tooltip";
import {useHistory} from "react-router-dom";
import ReportStateAmI from "../Shared/ReportStateAmI";
import {ReportState} from "../../../../../api/im";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import {routeNames} from "../../../../Navigation/routeNames";
import {showSuccessToast} from "../../../../../utils/toastUtils";

const ReportListActionItem = ({item, onActionConfirmed}: ReportListActionItemProps) => {
    const dispatch = useDispatch();
    const {loading} = useSelector((state: RootStore) => state.report);
    const history = useHistory();
    const {searchQueries} = usePageUrl();

    const onDeleteReport = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteReportFromService(item.actions));
        if (!success) {
            return false;
        }
        showSuccessToast("Deleted report");

        await onActionConfirmed();
        return success;
    };

    const reviewReport = () => {
        history.push({
            pathname: `${routeNames.reportReview.path}/${item.actions}`,
            search: searchQueries
        });
    };

    const viewReadonlyReport = () => {
        history.push({
            pathname: `${routeNames.reportView.path}/${item.actions}`,
            search: searchQueries
        });
    };

    const editReport = () => {
        history.push({
            pathname: `${routeNames.reportEdit.path}/${item.actions}`,
            search: searchQueries
        });
    };

    const getNonPendingReviewTooltip = () => {
        switch (item.reportState) {
            case ReportState.UnderReview:
                return "This report is currently under review by a manager. Click here to view the progress";
            case ReportState.CompletedReview:
                return "This report has been reviewed and marked as completed by the reviewing manager. Click here to view the completed report";
            default:
                return "";
        }
    };
    return (
        <React.Fragment>
            <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                <ReportStateAmI
                    reportStates={[ReportState.PendingReview]}
                    currentReportState={item.reportState}
                >
                    <div className="row ml-0 mr-0">
                        <div className="icon-tooltip-wrapper-md mr-3">
                            <Tooltip tooltipText={"Edit Report"} size={"md"} place={"left"}>
                                <Icon
                                    rootElement={"Div"}
                                    icon={IconType.Edit}
                                    size={"Medium"}
                                    className="icon-dark"
                                    onClick={editReport}
                                />
                            </Tooltip>
                        </div>
                        <IconWithModal
                            tooltipText={`Delete report?`}
                            iconType={IconType.Bin}
                            modalTitle={"Delete Report"}
                            modalBody={
                                "Are you sure you want to delete this report?. This operation cannot be undone!"
                            }
                            onConfirm={onDeleteReport}
                            loading={loading}
                        />
                    </div>
                </ReportStateAmI>
                <ReportStateAmI
                    reportStates={[ReportState.UnderReview, ReportState.CompletedReview]}
                    currentReportState={item.reportState}
                >
                    <div className="row ml-0 mr-0">
                        <div className="icon-tooltip-wrapper-md mr-3">
                            <Tooltip
                                tooltipText={getNonPendingReviewTooltip()}
                                size={"md"}
                                place={"left"}
                            >
                                <Icon
                                    rootElement={"Div"}
                                    icon={IconType.Eye}
                                    size={"Large"}
                                    className="icon-dark"
                                    onClick={viewReadonlyReport}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </ReportStateAmI>
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <div className="row ml-0 mr-0">
                    <div className="icon-tooltip-wrapper-md mr-3">
                        <Tooltip tooltipText={"Review Report"} size={"md"} place={"left"}>
                            <Icon
                                rootElement={"Div"}
                                icon={IconType.Eye}
                                size={"Medium"}
                                className="icon-dark"
                                onClick={reviewReport}
                            />
                        </Tooltip>
                    </div>
                    <IconWithModal
                        tooltipText={`Delete report?`}
                        iconType={IconType.Bin}
                        modalTitle={"Delete Report"}
                        modalBody={
                            "Are you sure you want to delete this report?. This operation cannot be undone!"
                        }
                        onConfirm={onDeleteReport}
                        loading={loading}
                    />
                </div>
            </AuthAmI>
        </React.Fragment>
    );
};

export default ReportListActionItem;

interface ReportListActionItemProps {
    item: ReportListTableRow;
    onActionConfirmed: () => Promise<void>;
}
